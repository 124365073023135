import { Box, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { EntrySwitch } from "../../../components/Entry";
// ...
import {
  EVENT_VISIBILITIES_DETAILS,
  REGISTRATION_METHODS_DETAILS,
} from "../entries";
import AccessCode from "./AccessCode";
import FormBuilder from "./FormBuilder";
import RegDetails from "./RegDetails";
// ...
import RegEntries from "./RegEntries";

const RegSection = withStyles((theme) => ({
  root: {
    display: "flex",
    margin: "0 0 135px 0",
    [theme.breakpoints.down("md")]: {
      margin: "0 0 60px 0",
    },
  },
  form: {
    fontFamily: "Montserrat, sans-serif",
    padding: "0 10% 0 0",
    "& .__title": {
      color: "#637381",
      fontSize: "20px",
      lineHeight: "22px",
      fontWeight: "600",
      margin: "0 0 35px 0",
    },
    "& .__entries": {
      margin: "0 0 20px 0",
    },
    "& .__helper": {
      color: "#333333",
      fontSize: "16px",
      lineHeight: "16px",
    },
    "& .__extra": {
      margin: "40px 0 0 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0 0 20px 0",
      "& .__title": {
        fontSize: "18px",
        lineHeight: "20px",
        margin: "0 0 16px 0",
      },
      "& .__entries": {
        margin: "0 0 16px 0",
      },
      "& .__helper": {
        color: "#333333",
        fontSize: "13px",
        lineHeight: "15px",
      },
      "& .__extra": {
        margin: "10px 0 0 0",
      },
    },
  },
  details: {},
}))(({ title, helper, entries, classes, disabled }) => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const history = useHistory();
  // ...
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext();

  // - Base
  const name = entries.name;
  const options = entries.options;
  const value = values?.[name];
  const isVis = name === "access";
  const isFields = name === "regLabel";

  // - Details
  const details = isVis
    ? EVENT_VISIBILITIES_DETAILS
    : REGISTRATION_METHODS_DETAILS;
  const currDetails = details?.[value];

  // - Extra
  const isVisPub = value === "public";
  const isVisRest = value === "restricted";
  const isVisPriv = value === "private";
  // ...
  const isFieldEasy = value === "easygo";
  const isFieldCustom = value === "customized";
  // ...
  const onLinkNav = (link) => {
    history.push(link);
  };
  // ...
  useEffect(() => {
    const current = values?.[name];
    if (name !== "regLabel" || values.requiredSignIn || current === "easygo")
      return;
    // ...
    setFieldValue("requiredSignIn", true);
  }, [values]);
  // ...
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6} className={classes.form}>
        <Box className="__title">{title}</Box>
        <Box className="__entries">
          <RegEntries
            name={name}
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            options={options}
            disabledEntries={disabled}
          />
        </Box>

        <Box
          className="__helper"
          style={{ marginTop: isMobile ? "20px" : "0" }}
        >
          {isVisPub && (
            <span>Public, everyone can register and attend the event</span>
          )}
          {isVisRest && (
            <span>
              Restricted , only people with access code can register. Please
              save and share the code with your event guests
            </span>
          )}
          {isVisPriv && (
            <>
              <span>
                Private, only invited people can access. Invite attendees
              </span>
              <span
                className="__reg__link"
                onClick={() => onLinkNav("/manage-attendees")}
              >
                here
              </span>
            </>
          )}
        </Box>

        {isVis && isVisRest && (
          <Box className="__extra">
            <AccessCode />
          </Box>
        )}
      </Grid>

      <Grid item xs={12} md={6} className={classes.details}>
        <RegDetails
          {...currDetails}
          isVisPriv={isVisPriv}
          onLinkNav={onLinkNav}
        />
      </Grid>

      {isFields && (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: "20px 0 0 -16px",
            "& .EntrySwitch": { color: "#637381" },
          }}
        >
          <EntrySwitch
            name="ticketAttachement"
            label="Attach tickets on confirmation emails"
          />
          <EntrySwitch
            name="requiredSignIn"
            label="Require attendees to signup on the platform"
            d
            isabled={!isFieldEasy}
          />
          <EntrySwitch
            name="disableVenue"
            label="Disable the venue featue (i don't need virtual space)"
          />
        </Grid>
      )}

      {isFields && isFieldCustom && (
        <Grid item xs={12} md={12}>
          <FormBuilder />
        </Grid>
      )}
    </Grid>
  );
});

export default RegSection;
