import "./style.scss";

import { Box, Container, Grid, Typography } from "@material-ui/core";
import cx from "classnames";
import React, { useEffect, useState } from "react";

import http from "../../api/http";
import Svg from "../Svg";
import GaugeChart from "react-gauge-chart";

const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

const EventPrediction = ({ event, estimationSet }) => {

  const [participantsNumberMin, setParticipantsNumberMin] = useState(null);
  const [participantsNumberMax, setParticipantsNumberMax] = useState(null);
  const [count, setCount] = useState(1); 
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  // ...
  
  useEffect(() => {
    if(!event || !event.id) return;
    const doPrediction = async () => {
      setLoading(true);
      //console.log("call prediction . . .");
      const prediction = await http.GET(`/organizations/events/${event.id}/${estimationSet ? "predictParticipantsCom" : "predictParticipants"}?${serialize(estimationSet||{})}`);
      //console.log("prediction: ", prediction);
      if(prediction && prediction.length>1){
        const min = _.min(prediction);        
        const max = _.max(prediction);
        //console.log("min: ", Math.round(min));
        //console.log("max: ", Math.round(max));
        setParticipantsNumberMin(Math.round(min));
        setParticipantsNumberMax(Math.round(max));
      }      
      setLoading(false);
    }
    const getCount = async () => {
      //console.log("call prediction . . ."); /organizations/events/:id/getRegsiteredCount
      const countParticipant = await http.GET(`/organizations/events/${event.id}/getRegsiteredCount`);
      //console.log("countParticipant: ", countParticipant);
      if(countParticipant>1){
        setCount(countParticipant);
      }
    }
    try {
      if(!event || !event.id) return;
      getCount();
      doPrediction();
    } catch (error) {
      console.error("doPrediction error : ", error);      
      setLoading(false);
    }
    
  }, [event.id, estimationSet]);
  // ...
  useEffect(() => {
    if(!participantsNumberMax || count<1) return;
    const p = count / (participantsNumberMax || 1);
    //console.log("percent: ", percent);
    setPercent(p);
  }, [participantsNumberMax, count]);
  // ...
  return (
    <Grid  container spacing={2} className="EventPrediction">
      <Grid item xs={6} className="EventPrediction__LSide">
        <Typography className="EventPrediction__header">
            Event registration live prediction
        </Typography>
        <Grid  container gap={2} >
          <Grid item xs={5} className="EventPrediction__Box" >
              <div className="EventPrediction__Box__LSide">
                <Typography className="EventPrediction__Box__Typo">
                  Minimum registration predicted
                </Typography>
                <div className="EventPrediction__Box__Count">
                  <Svg src="/static/prediction/down.svg"/>
                  {loading ? <p>Loading...</p> : <Typography className="EventPrediction__Box__Digit">
                    {participantsNumberMin}
                  </Typography>}
                </div>                
              </div>
              <Grid item xs={4}>
                <Svg src="/static/prediction/user-plus.svg"/>
              </Grid>
          </Grid>
          <Grid item xs={5} className="EventPrediction__Box">
              <div className="EventPrediction__Box__LSide">
                <Typography className="EventPrediction__Box__Typo">
                  Maximum registration predicted
                </Typography>
                <div className="EventPrediction__Box__Count">
                  <Svg src="/static/prediction/up.svg"/>
                  {loading ? <p>Loading...</p> : <Typography className="EventPrediction__Box__Digit">
                    {participantsNumberMax}
                  </Typography>}
                </div>                
              </div>
              <Grid item xs={4}>
                <Svg src="/static/prediction/user-plus.svg"/>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <GaugeChart className="EventPrediction__RSide" id={`gauge-chart-${event?.id}`}
          nrOfLevels={3} 
          
          percent={percent} 
          colors={["#FE001E", "#FF7D05", "#7ED321"]} 
          cornerRadius={1}
          arcPadding={0.02} 
          textColor="#414D55"
          formatTextValue={(v) => `${count} registered`}
          style={{
            fontSize: "10px",
            margin: "auto 100px"
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EventPrediction;
